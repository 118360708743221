<template>
    <div class="my_files_widget h-full">
        <vue2Files 
            :isMyFiles="true"
            :isFounder="true"
            :isStudent="true"/>
    </div>
</template>

<script>

export default {
    name: 'PageWidgetsFiles',
    components: {
        vue2Files: () => import('@apps/vue2Files'),
    },
    
}
</script>

<style lang="scss" scoped>
.my_files_widget {
    padding: 15px;
}
</style>
